import React, { useState, useEffect } from "react"
import SEO from "../../components/seo"
import styled from "styled-components"
import { ArticlePage, theme } from "@rily/components"
import { graphql } from "gatsby"
import { slug } from "../../utils/slug"

const Container = styled.div`
  background-color: ${theme.colors.white};
  overflow-x: hidden;
`

const Article = ({ data }) => {
  const post = data.allArticle.edges[0].node

  const [article, setArticle] = useState<any>(post)

  // grab recipes / authors and transform data before sending it down to the page component
  const getArticleData = async data => {
    if (data?.body) {
      const newBody = await Promise.all(
        data.body.map(async block => {
          if (block.recipe) {
            let newRecipe = {}

            await fetch(`https://api.rily.co/recipes/${block.recipe.recipeId}`)
              .then(response => response.json())
              .then(data => {
                newRecipe = {
                  ...block,
                  recipe: {
                    ...data,
                    cookTime: data.cook_time,
                    ingredients: data.recipe_ingredient,
                  },
                }
              })

            return newRecipe
          } else {
            return block
          }
        })
      )
      let footer = {
        articleTheme: theme.categoryColors[data.category],
        tags:
          data.tags.map(tag => {
            return tag.name
          }) || [],
        author: {
          name: data.author.name,
        },
        articles: [{ articleType: "Popular Articles", articles: [] }],
      }

      // get related recipe images
      if (data.related_recipes) {
        const fetchedRecipes = await Promise.all(
          data.related_recipes
            .map(async recipe => {
              let newRecipe: any = {}
              await fetch(`https://api.rily.co/recipes/${recipe.id}`)
                .then(response => response.json())
                .then(data => {
                  newRecipe = {
                    ...recipe,
                    images: data.images,
                    title: recipe.name,
                    linkUrl: `/recipes/${slug(recipe.name)}`,
                  }
                })

              return newRecipe
            })
            .filter(async recipe => {
              return recipe.images !== undefined
            })
        )

        // filter out imageless recipes before adding to footer
        // in case of cms user error
        footer.articles.push({
          articleType: "Related Recipes",
          articles: fetchedRecipes.filter((recipe: any) => {
            return recipe.images !== undefined
          }),
        })
      }

      // get popular articles

      await fetch(`https://editorial.rily.co/articles?_limit=6`)
        .then(res => res.json())
        .then(popArticles => {
          footer.articles[0].articles = popArticles
            .filter(post => {
              return post.title !== data.title
            })
            .map(post => {
              return {
                id: post.id,
                images: [post.header.url],
                title: post.title,
                linkUrl: `/articles/${slug(post.title)}`,
              }
            })
            .slice(0, 5)
        })

      // get author info
      await fetch(`https://api.rily.co/authors/${data.author.authorId}`)
        .then(response => response.json())
        .then(data => (footer.author = data))

      const newArticle = { ...data, body: newBody, footer: footer }

      setArticle(newArticle)
    }
  }

  useEffect(() => {
    getArticleData(post)
  }, [])

  return (
    <Container>
      <>
        <SEO
          article={article}
          title={article.title}
          description={article.subtitle}
          pagePath={slug(article.title)}
          image={article.header.url}
        />
        <ArticlePage data={article} />
      </>
    </Container>
  )
}

export const query = graphql`
  query ArticlePageQuery($id: String) {
    allArticle(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          id
          subtitle
          tags {
            name
          }
          related_recipes {
            name
            id
          }
          header {
            _xtypename
            alternativeText
            caption
            url
            width
            size
            height
            id
          }
          author {
            authorId
            created_at
            id
            name
            updated_at
          }
          category
          created_at
          updated_at
          body {
            _xtypename
            caption
            heading
            id
            image {
              _xtypename
              alternativeText
              caption
              id
              url
            }
            largeImage1 {
              _xtypename
              alternativeText
              caption
              id
              url
            }
            largeImage2 {
              _xtypename
              caption
              id
              url
              alternativeText
            }
            image1 {
              _xtypename
              alternativeText
              caption
              id
              url
            }
            image2 {
              _xtypename
              caption
              id
              url
              alternativeText
            }
            overrideImage {
              _xtypename
              alternativeText
              id
              imageCaption
              url
            }
            recipe {
              id
              name
              recipeId
              _xtypename
            }
            recipeCaption
            text
          }
        }
      }
    }
  }
`

export default Article
